import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import {
  faTruckMoving,
  faGlobe,
  faBus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Skill = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;
const SkillBall = styled.div`
  border-radius: 128px;
  width: 128px;
  height: 128px;
  background-color: #980d27;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const FaIcon = styled(FontAwesomeIcon)`
  font-size: 42px;
  color: white;
  margin-top: 10px;
`;

const SkillTitle = styled.h5`
  color: white;
  padding-top: 10px;
  font-size: 1em;
`;

const SubTitleBall = styled.h5`
  color: white;
  font-size: 3.3em;
  font-weight: bold;
`;

const DescriptionSection = () => {
  return (
    <div style={{ textAlign: "center", width: "100%", alignSelf: "center" }}>
      <Container
        fluid
        style={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col xs={12} md={4} lg={4}>
            <Skill>
              <SkillBall>
                <FaIcon icon={faTruckMoving} />
                <SubTitleBall>15</SubTitleBall>
              </SkillBall>
              <SkillTitle>CAMIONES REVOLVEDORES</SkillTitle>
            </Skill>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <Skill>
              <SkillBall>
                <FaIcon icon={faGlobe} />
                <SubTitleBall>∞</SubTitleBall>
              </SkillBall>
              <SkillTitle>
                ABARCA TODA LA AREA METROPOLITANA DE MONTERREY
              </SkillTitle>
            </Skill>
          </Col>
          <Col xs={12} md={4} lg={4}>
            <Skill>
              <SkillBall>
                <FaIcon icon={faBus} />
                <SubTitleBall>5</SubTitleBall>
              </SkillBall>
              <SkillTitle>BOMBAS A DISPOSICIÓN</SkillTitle>
            </Skill>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DescriptionSection;

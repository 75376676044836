import { ProductoType, ProyectoType, ServicioType } from "./interfaces";

export const proyectosArray: ProyectoType[] = [
  {
    titulo: "NAVE 01",
    m3: 300,
    municipio: "Apodaca",
    imagen:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Felipe_Calderon_20090130_%28cropped%29.jpg",
  },
  {
    titulo: "CALZADA DEL VALLE (PAVIMENTOS Y BANQUETAS)",
    m3: 300,
    municipio: "San Pedro Garza García",
    imagen:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Felipe_Calderon_20090130_%28cropped%29.jpg",
  },
  {
    titulo: "LIVERPOOL GALERIAS",
    m3: 300,
    municipio: "Monterrey",
    imagen:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Felipe_Calderon_20090130_%28cropped%29.jpg",
  },
  {
    titulo: "PARQUE FINSA",
    m3: 300,
    municipio: "Guadalupe",
    imagen:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Felipe_Calderon_20090130_%28cropped%29.jpg",
  },
  {
    titulo: "PLAZA MAGNUS",
    m3: 300,
    municipio: "San Jeronimo",
    imagen:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Felipe_Calderon_20090130_%28cropped%29.jpg",
  },
  {
    titulo: "PLAZA MAGNUS",
    m3: 300,
    municipio: "Guadalupe",
    imagen:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Felipe_Calderon_20090130_%28cropped%29.jpg",
  },
];

export const productosArray: ProductoType[] = [
  {
    titulo: "Concreto Premezclado",
    descripcion:
      "Concreto hecho de la mejor calidad de cemento, agua y agregados para diferentes niveles de resistencia dependiendo de tu proyecto.",
    imagen:
      "https://forte-uploads.s3.us-east-2.amazonaws.com/Productos/premezclado.jpg",
  },
  {
    titulo: "Concreto Bombeado",
    descripcion: "Concreto ligero que permite su bombeo a traves de manguera.",
    imagen:
      "https://forte-uploads.s3.us-east-2.amazonaws.com/Productos/bombeado.jpeg",
  },
  {
    titulo: "Concreto Acelerado",
    descripcion:
      "Concreto que elabora un nivel de resistencia de manera rápida y eficiente (24, 48 y 72 horas).",
    imagen:
      "https://forte-uploads.s3.us-east-2.amazonaws.com/Productos/acelerado.jpeg",
  },
  {
    titulo: "Morteros",
    descripcion:
      "Conglomerante de arena, agua y cemento, perfecto para aparejar ladrillos, bloques, piedras, etc.",
    imagen:
      "https://forte-uploads.s3.us-east-2.amazonaws.com/Productos/mortero.jpg",
  },
  {
    titulo: "Rellenos fluidos",
    descripcion:
      "Producto con alta fluidez, autocompactibilidad, resistencia y cohesión, perfecto para anjas y tuberias.",
    imagen:
      "https://forte-uploads.s3.us-east-2.amazonaws.com/Productos/rellenofluido.jpg",
  },
  {
    titulo: "Concreto MR",
    descripcion:
      "Producto para proyectos sobre terrenos, así como: patios, estacionamientos, pavimento, etc.",
    imagen:
      "https://forte-uploads.s3.us-east-2.amazonaws.com/Productos/moduloruptura.jpeg",
  },
  {
    titulo: "Concreto con Aditivos",
    descripcion:
      "Concreto con variedad de aditivos dependiendo de las necesidades, así como: Acelerantes, Fibra Polipropileno, Impermeabilizante y Superfludificantes.",
    imagen:
      "https://forte-uploads.s3.us-east-2.amazonaws.com/Productos/aditivos.jpg",
  },
];

export const serviciosArray: ServicioType[] = [
  {
    titulo: "Servicio 1",
    descripcion:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ut hendrerit leo. Etiam ante leo, rhoncus et rhoncus nec, eleifend pharetra massa. In sapien orci, pretium at felis vel, elementum porta ipsum. Maecenas laoreet pulvinar ornare. Phasellus rutrum volutpat dolor suscipit elementum.",
    imagen:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Felipe_Calderon_20090130_%28cropped%29.jpg",
  },
  {
    titulo: "Servicio 2",
    descripcion:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ut hendrerit leo. Etiam ante leo, rhoncus et rhoncus nec, eleifend pharetra massa. In sapien orci, pretium at felis vel, elementum porta ipsum. Maecenas laoreet pulvinar ornare. Phasellus rutrum volutpat dolor suscipit elementum.",
    imagen:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Felipe_Calderon_20090130_%28cropped%29.jpg",
  },
  {
    titulo: "Servicio 3",
    descripcion:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ut hendrerit leo. Etiam ante leo, rhoncus et rhoncus nec, eleifend pharetra massa. In sapien orci, pretium at felis vel, elementum porta ipsum. Maecenas laoreet pulvinar ornare. Phasellus rutrum volutpat dolor suscipit elementum.",
    imagen:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Felipe_Calderon_20090130_%28cropped%29.jpg",
  },
  {
    titulo: "Servicio 4",
    descripcion:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ut hendrerit leo. Etiam ante leo, rhoncus et rhoncus nec, eleifend pharetra massa. In sapien orci, pretium at felis vel, elementum porta ipsum. Maecenas laoreet pulvinar ornare. Phasellus rutrum volutpat dolor suscipit elementum.",
    imagen:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Felipe_Calderon_20090130_%28cropped%29.jpg",
  },
  {
    titulo: "Servicio 5",
    descripcion:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ut hendrerit leo. Etiam ante leo, rhoncus et rhoncus nec, eleifend pharetra massa. In sapien orci, pretium at felis vel, elementum porta ipsum. Maecenas laoreet pulvinar ornare. Phasellus rutrum volutpat dolor suscipit elementum.",
    imagen:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Felipe_Calderon_20090130_%28cropped%29.jpg",
  },
  {
    titulo: "Servicio 6",
    descripcion:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ut hendrerit leo. Etiam ante leo, rhoncus et rhoncus nec, eleifend pharetra massa. In sapien orci, pretium at felis vel, elementum porta ipsum. Maecenas laoreet pulvinar ornare. Phasellus rutrum volutpat dolor suscipit elementum.",
    imagen:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Felipe_Calderon_20090130_%28cropped%29.jpg",
  },
  {
    titulo: "Servicio 7",
    descripcion:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ut hendrerit leo. Etiam ante leo, rhoncus et rhoncus nec, eleifend pharetra massa. In sapien orci, pretium at felis vel, elementum porta ipsum. Maecenas laoreet pulvinar ornare. Phasellus rutrum volutpat dolor suscipit elementum.",
    imagen:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Felipe_Calderon_20090130_%28cropped%29.jpg",
  },
];

import React from "react";
import styled from "styled-components";

const ContactBarStyled = styled.div`
  background-color: #bb0729;
  color: white;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 468px) {
    display: none;
  }
`;

const ContactBar = () => {
  return (
    <ContactBarStyled>
      <p
        style={{
          fontSize: "14px",
          marginBottom: 0,
          marginTop: 0,
          marginRight: "10px",
        }}
      >
        ventas@forteconcretos.com | tel: 81 8777 2429
      </p>
    </ContactBarStyled>
  );
};
export default ContactBar;

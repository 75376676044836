import React from "react";
import FotoCamion from "../img/nosotros.jpg";
import styled from "styled-components";
import { Row, Container, Button } from "react-bootstrap";
import Producto from "../components/Producto";
import { productosArray } from "../data/data";
import Pdf from "../data/ficha.pdf";

const ParallaxContainer = styled.div`
  width: 100%;
  height: 420px;
  background: url(${FotoCamion}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const SeparatorLine = styled.hr`
  border-top: 3px solid #bb0729;
  width: 50%;
  margin: 0 auto;
`;

const FirstSection = styled.section`
  padding-top 30px;
  align-self: center;
  display: flex;
  justify-content: center;
`;

const MainTitle = styled.h1`
  color: black;
  text-align: center;
`;

const ProductRow = styled(Row)`
  display: flex;
  justify-content: center;
`;

const DownloadPDF = styled.a`
  href={Pdf}
  target="_blank"
  download="FICHAS TECNICAS PRODUCTOS FORTE CONCRETOS.pdf"
`;

const Productos = () => {
  return (
    <div>
      <ParallaxContainer />
      <FirstSection>
        <div>
          <MainTitle>NUESTROS PRODUCTOS</MainTitle>
          <SeparatorLine />
          <div style={{ textAlign: "center", paddingBottom: "30px" }}>
            <Button
              variant="danger"
              style={{
                marginTop: "3em",
                borderRadius: 35,
                backgroundColor: "#bb0729",
                padding: "14px 18px",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              <a
                href={Pdf}
                download="FICHAS TECNICAS PRODUCTOS FORTE CONCRETOS.pdf"
                style={{ color: "white" }}
              >
                DESCARGA NUESTRA FICHA TÉCNICA
              </a>
            </Button>
          </div>
        </div>
      </FirstSection>
      <Container style={{ padding: "16px" }}>
        <ProductRow>
          {productosArray.map((item, index) => {
            return <Producto producto={item} />;
          })}
        </ProductRow>
      </Container>
    </div>
  );
};

export default Productos;

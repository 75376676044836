import React from "react";
import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import "./App.css";
import Landing from "./pages/Landing";
import Productos from "./pages/Productos";
import Servicios from "./pages/Servicios";
import Nosotros from "./pages/Nosotros";
import Contacto from "./pages/Contacto";

import NavbarComponent from "./components/NavbarComponent";
import ContactBar from "./components/ContactBar";
import FooterPage from "./components/FooterComponenet";

function App() {
  return (
    <Router>
      <div>
        <ContactBar />
        <NavbarComponent />
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/productos">
            <Productos />
          </Route>
          <Route path="/servicios">
            <Servicios />
          </Route>
          <Route path="/nosotros">
            <Nosotros />
          </Route>
          <Route path="/contacto">
            <Contacto />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
        <FooterPage />
      </div>
    </Router>
  );
}

export default App;

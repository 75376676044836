import React from "react";
import FotoCamion from "../img/nosotros.jpg";
import FotoMedidor from "../img/medidor.jpg";
import FotoPlanta from "../img/planta.jpg";
import styled from "styled-components";
import { keyframes } from "styled-components";
import { Row, Col } from "react-bootstrap";
import ForteCamion from "../img/Nosotros/calzada.jpeg";

// Fotos de galleria
import gallery1 from "../img/Nosotros/cinco.jpeg";
import gallery2 from "../img/Nosotros/tshirt1.jpeg";
import gallery3 from "../img/Nosotros/ocho.jpeg";
import gallery4 from "../img/Nosotros/tres.jpeg";
import gallery5 from "../img/Nosotros/MuroContencion.jpeg";
import gallery6 from "../img/Nosotros/plantanoche.jpeg";

const slideRightAnimation = keyframes`
  0%   {width: 0%;}
  100%  {width: 100%;}
`;

const ParallaxContainer = styled.div`
  width: 100%;
  height: 420px;
  background: url(${FotoCamion}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const ParallaxContainerTwo = styled.div`
  width: 100%;
  height: 100%;
  background: url(${FotoMedidor}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
  animation-name: ${slideRightAnimation};
  animation-duration: 12s;
  animation-iteration-count: 1;
  border-left: solid;
  border-right: solid;
`;

const ParallaxContainerThree = styled.div`
  width: 100%;
  height: 300px;
  background: url(${FotoPlanta}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
  animation-name: ${slideRightAnimation};
  animation-duration: 12s;
  animation-iteration-count: 1;
  border-left: solid;
  border-right: solid;
`;

const CrossFadeImg = styled.img`
  position: absolute;
  left: 0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  &:hover {
    opacity: 0;
  }
`;

const FirstSection = styled.section`
  padding-top: 30px;
  align-self: center;
  display: flex;
  justify-content: center;
`;

const SecondSection = styled.section`
  background: grey;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px
  padding-left: 10px;
  width: 100%;
  text-align: center;
  text-align: -webkit-center;
`;

const ThirdSection = styled.section`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
`;

const FourthSection = styled.section`
  padding-top: 30px;
  padding-bottom: 10px;
  display: flex;
`;

interface PropsImg {
  imagen: string;
}

const ImagenServicio = styled.div`
  max-width: 100%;
  height: 350px;
  background: url(${(props: PropsImg) => props.imagen}) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;

  &:hover {
    transform: scale(1.1);
    filter: grayscale(0);
  }
`;

const ImageContainer = styled.div`
  overflow: hidden;
  max-width: 100%;
`;
const SeparatorLineR = styled.hr`
  padding-bottom: 30px;
  border-top: 3px solid #bb0729;
  width: 50%;
  margin: 0 auto;
`;

const SeparatorLineB = styled.hr`
  padding-bottom: 30px;
  border-top: 3px solid black;
  width: 50%;
  margin: 0 auto;
`;

const MainTitle = styled.h1`
  color: black;
  text-align: center;
`;

const ParagraphText = styled.p`
  color: black;
`;

const GalleryCol = styled(Col)`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 5px;
  overflow: hidden;
`;

const GalleryImage = styled.img`
  background: url(${(props) => props.src}) no-repeat center center fixed;
  height: 350px;
  width: 100%;
  object-fit: cover;
  transition: 0.5s all ease-in-out;
  filter: grayscale(50%);

  &:hover {
    transform: scale(1.1);
    filter: grayscale(0);
  }
`;

const Nosotros = () => {
  return (
    <div>
      <ParallaxContainer />
      <FirstSection>
        <Row style={{ width: "100%" }}>
          <Col xs={12} md={6} style={{ textAlign: "center" }}>
            <MainTitle>SOBRE NOSOTROS</MainTitle>
            <SeparatorLineR />
          </Col>
          <Col xs={12} md={6}>
            <ParagraphText
              style={{ paddingLeft: "50px", paddingRight: "50px" }}
            >
              Somos una empresa que produce y suministra concreto premezclado
              con materia prima de la más alta calidad, siempre cumpliendo con
              los estándares de calidad de las normas mexicanas.
            </ParagraphText>
            <br />
            <ParagraphText
              style={{ paddingLeft: "50px", paddingRight: "50px" }}
            >
              <span style={{ color: "#bb0729" }}>FORTE CONCRETOS </span>se
              compromete a dar un servicio de principio a fin de tu proyecto, de
              manera personalizada, buscando proveer concreto con precios
              competitivos y con la mejor calidad de mercado.
            </ParagraphText>
          </Col>
        </Row>
      </FirstSection>
      <FirstSection>
        <Row style={{ width: "100%" }}>
          <Col xs={12} md={6} style={{ textAlign: "center" }}>
            <ImageContainer>
              <ImagenServicio imagen={ForteCamion} />
            </ImageContainer>
          </Col>
          <Col xs={12} md={6}>
            <MainTitle>NUESTROS PROYECTOS</MainTitle>
            <SeparatorLineR />
            <ul style={{ marginLeft: "2.2em" }}>
              <li>
                NAVE 01 <span style={{ fontWeight: "bold" }}>APODACA</span>
              </li>
              <li>
                CALZADA DEL VALLE{" "}
                <span style={{ fontWeight: "bold" }}>
                  SAN PEDRO GARZA GARCÍA
                </span>
              </li>
              <li>
                CENTRIKA HUINALA{" "}
                <span style={{ fontWeight: "bold" }}>APODACA</span>
              </li>
              <li>
                LIVERPOOL GALERIAS MTY{" "}
                <span style={{ fontWeight: "bold" }}>MONTERREY</span>
              </li>
              <li>
                PLAZA MAGNUS{" "}
                <span style={{ fontWeight: "bold" }}>SAN JERONIMO</span>
              </li>
              <li>
                PARQUE INDUSTRIAL 100{" "}
                <span style={{ fontWeight: "bold" }}>SANTA CATARINA</span>
              </li>
              <li>
                {" "}
                KIARA RESIDENCIAL{" "}
                <span style={{ fontWeight: "bold" }}>MONTERREY</span>
              </li>
              <li>
                {" "}
                PARQUE LOGISTICO LOS LERMAS{" "}
                <span style={{ fontWeight: "bold" }}>GUADALUPE</span>
              </li>
              <li>
                FRACCIONAMIENTO PASEO DE SALINAS{" "}
                <span style={{ fontWeight: "bold" }}>SALINAS VICTORIA</span>
              </li>
              <li>
                {" "}
                FRACCIONAMIENTO PUNTA LA BOCA{" "}
                <span style={{ fontWeight: "bold" }}>SANTIAGO</span>
              </li>
              <li>
                {" "}
                PARQUE FINSA <span style={{ fontWeight: "bold" }}>APODACA</span>
              </li>
            </ul>
          </Col>
        </Row>
      </FirstSection>
      <SecondSection>
        <Row
          style={{ paddingLeft: "40px", paddingRight: "40px", width: "100%" }}
        >
          <GalleryCol xs={12} md={6}>
            <GalleryImage src={gallery1} />
          </GalleryCol>
          <GalleryCol xs={12} md={6}>
            <GalleryImage src={gallery2} />
          </GalleryCol>
          <GalleryCol xs={12} md={6}>
            <GalleryImage src={gallery3} />
          </GalleryCol>
          <GalleryCol xs={12} md={6}>
            <GalleryImage src={gallery4} />
          </GalleryCol>
          <GalleryCol xs={12} md={6}>
            <GalleryImage src={gallery5} />
          </GalleryCol>
          <GalleryCol xs={12} md={6}>
            <GalleryImage src={gallery6} />
          </GalleryCol>
        </Row>
      </SecondSection>

      <ThirdSection>
        <Row style={{ width: "100%" }}>
          <Col xs={12} md={6} style={{ textAlign: "center" }}>
            <MainTitle>SUPERVISIÓN DE CALIDAD</MainTitle>
            <SeparatorLineR />
            <div>
              <ParagraphText
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
              >
                Forte Concretos cuenta con una supervisión de calidad en cada
                servicio y producto que proporciona. Con el apoyo de doctores de
                la <b>UANL</b>, la <b>automatización digital</b> de la mezcla de
                materiales y las constantes <b>pruebas</b> de calidad, se logra
                un cumplimiento de los estándares y las normas mexicanas.
              </ParagraphText>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <ImageContainer>
              <ImagenServicio imagen={FotoMedidor} />
            </ImageContainer>
          </Col>
        </Row>
      </ThirdSection>

      <SeparatorLineB />

      <FirstSection>
        <Row style={{ width: "100%" }}>
          <Col xs={12} md={6} style={{ textAlign: "center" }}>
            <ImageContainer style={{ marginBottom: "30px" }}>
              <ImagenServicio imagen={FotoPlanta} />
            </ImageContainer>
          </Col>
          <Col xs={12} md={6}>
            <MainTitle>PLANTA</MainTitle>
            <SeparatorLineR />
            <ParagraphText
              style={{
                paddingLeft: "50px",
                paddingRight: "50px",
                textAlign: "center",
              }}
            >
              Santa Catarina, NL
            </ParagraphText>
          </Col>
        </Row>
      </FirstSection>
    </div>
  );
};

export default Nosotros;

import React from "react";
import ForteCamion from "../img/forte-camion.jpg";
import FotoCamion from "../img/bombeo.jpeg";
import Planta from "../img/tuberia.jpg";

import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

const ParallaxContainer = styled.div`
  width: 100%;
  height: 420px;
  background: url(${ForteCamion}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const MainTitle = styled.h1`
  color: black;
  align-self: center;
`;

const Subtitle = styled.h2`
  color: black;
  align-self: center;
  margin-bottom: 1em;
`;

const TitleSection = styled.div`
  width: 100%;
  text-align: center;
`;

const SubtitleSection = styled.div`
  width: 100%;
  text-align: center;
  -ms-transform: translateY(-50%);
  transform: translateY(40%);
`;

const ContenidoServicio = styled.p`
  text-align: center;
  margin-top: 15px;
`;

const ImagenServicio = styled.div`
  width: 100%;
  height: 300px;
  background: url(${(props) => (props.id == "1" ? FotoCamion : Planta)})
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;

  &:hover {
    transform: scale(1.1);
    filter: grayscale(0);
  }
`;

const ImageContainer = styled.div`
  overflow: hidden;
  max-width: 100%;
`;

const SeparatorLineR = styled.hr`
  padding-bottom: 30px;
  border-top: 3px solid #bb0729;
  width: 50%;
`;

const FirstSection = styled.section`
  padding-top: 30px;
  align-self: center;
  display: flex;
  justify-content: center;
`;

const SecondSection = styled.section`
  padding-bottom: 40px;
`;

const Servicios = () => {
  return (
    <div>
      <ParallaxContainer />

      <FirstSection>
        <TitleSection>
          <MainTitle>SERVICIOS</MainTitle>
          <SeparatorLineR />
        </TitleSection>
      </FirstSection>

      <SecondSection>
        <Container>
          <Row>
            <Col xs={12} lg={6}>
              <SubtitleSection>
                <Subtitle>Servicio de Bombeo</Subtitle>
              </SubtitleSection>
              <ContenidoServicio>
                Tenemos a tu disposición la renta de equipo para el bombeo de
                concreto dependiendo de tus necesidades.{" "}
              </ContenidoServicio>
            </Col>
            <Col xs={12} lg={6}>
              <ImageContainer>
                <ImagenServicio id="1" />
              </ImageContainer>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col xs={12} lg={6}>
              <ImageContainer>
                <ImagenServicio id="2" />
              </ImageContainer>
            </Col>
            <Col xs={12} lg={6}>
              <SubtitleSection>
                <Subtitle>Instalaciones de Mangueras y Tubería</Subtitle>
              </SubtitleSection>
              <ContenidoServicio>
                Atendemos tus necesidades de instalación de mangueras y
                tuberias.
              </ContenidoServicio>
            </Col>
          </Row>
        </Container>
      </SecondSection>
    </div>
  );
};

export default Servicios;

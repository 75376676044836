import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

const FooterContainer = styled.section`
  background-color: #212121;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const FooterSubTitle = styled.h4`
  color: white;
  font-weight: bold;
  border-left: 1px white solid;
  border-right: 1px white solid;
`;

const FooterContent = styled.p`
  color: grey;
`;

const FooterCopyRights = styled.div`
  background-color: black;
  height: 50px;
  padding-left: 20px;
`;

const VerticalCenter = styled.div`
  margin: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(40%);
`;

const FooterPage = () => {
  return (
    <div>
      <FooterContainer>
        <Container fluid>
          <Row
            style={{
              justifyContent: "center",
              textAlign: "center",
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            <Col xs={12} md={6} lg={4}>
              <FooterSubTitle>FORTE</FooterSubTitle>
              <FooterContent>
                Surtidora de concreto con la certificación de calidad de la
                UANL.
              </FooterContent>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <FooterSubTitle>UBICACIONES</FooterSubTitle>
              <FooterContent>
                <b>Oficinas</b> localizadas en San Pedro Garza García, enfrente
                de Main Entrance.
                <br />
                <b>Planta</b> localizada en Santa Catarina, N.L.
              </FooterContent>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <FooterSubTitle>CONTACTO</FooterSubTitle>
              <FooterContent style={{ textAlign: "center" }}>
                <p>
                  <b>
                    81 8777 2429
                    <br />
                    ventas@forteconcretos.com
                  </b>
                </p>
              </FooterContent>
            </Col>
          </Row>
        </Container>
      </FooterContainer>
      <FooterCopyRights>
        <VerticalCenter>
          <FooterContent style={{ textAlign: "center" }}>
            &#169; Copyright - Forte Concretos
          </FooterContent>
        </VerticalCenter>
      </FooterCopyRights>
    </div>
  );
};

export default FooterPage;

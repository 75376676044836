import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import {
  faAddressBook,
  faArchive,
  faAward,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Skill = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;
const SkillBall = styled.div`
  border-radius: 128px;
  width: 128px;
  height: 128px;
  background-color: #980d27;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FaIcon = styled(FontAwesomeIcon)`
  font-size: 42px;
  color: white;
`;

const SkillTitle = styled.h5`
  color: white;
  padding-top: 10px;
`;

const CharecteristicSection = () => {
  return (
    <div style={{ textAlign: "center", width: "100%", alignSelf: "center" }}>
      <Container
        fluid
        style={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col xs={12} md={6} lg={3}>
            <Skill>
              <SkillBall>
                <FaIcon icon={faAddressBook} />
              </SkillBall>
              <SkillTitle>ASESORIA</SkillTitle>
            </Skill>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Skill>
              <SkillBall>
                <FaIcon icon={faUserFriends} />
              </SkillBall>
              <SkillTitle>SERVICIO</SkillTitle>
            </Skill>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Skill>
              <SkillBall>
                <FaIcon icon={faArchive} />
              </SkillBall>
              <SkillTitle>PUNTUALIDAD</SkillTitle>
            </Skill>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Skill>
              <SkillBall>
                <FaIcon icon={faAward} />
              </SkillBall>
              <SkillTitle>CALIDAD</SkillTitle>
            </Skill>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CharecteristicSection;

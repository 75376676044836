import React from "react";
import styled from "styled-components";
import { Col, Button } from "react-bootstrap";

const ProductContainer = styled.div`
  width: 348px;
  height: 348px;
  margin-top: 10px;
`;

const ContainerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 100%;
`;

const ProductTitle = styled.h5`
  color: white;
  z-index: 1001;
`;
const ProductDescription = styled.h5`
  color: white;
  font-size: 12px;
  text-align: center;
  padding: 6px;
  z-index: 1001;
`;

const CustomProductButtonSeeMore = styled(Button)`
  background-color: #bb0729;
  border: none;
  &:hover {
    background-color: #980d27;
  }
  outline: none;
`;
const CustomProductButtonBack = styled(Button)`
  background-color: #520514;
  border: none;
  &:hover {
    background-color: #1f0107;
  }
`;

interface ProductoType {
  titulo: string;
  descripcion: string;
  imagen: string;
}
interface ProductoProps {
  producto: ProductoType;
}

const Producto = (props: ProductoProps) => {
  const [isDescription, setIsDescription] = React.useState(false);

  const handleView = () => {
    setIsDescription(!isDescription);
  };

  return (
    <div>
      <Col xs={12} md={4} lg={3}>
        <div>
          <div
            style={{
              position: "absolute",
              width: "348px",
              height: "92%",
              zIndex: 1000,
              background:
                "linear-gradient(90deg, rgba(2,0,36,0.228466386554622) 100%, rgba(223,222,245,0.18671218487394958) 100%)",
            }}
          />
          <ProductContainer
            style={{
              backgroundImage: `url(${props.producto.imagen})`,
              backgroundSize: "cover",
            }}
          >
            {isDescription ? (
              <ContainerItem>
                <ProductDescription style={{ marginTop: "auto" }}>
                  {props.producto.descripcion}
                </ProductDescription>
                <CustomProductButtonBack
                  style={{ marginTop: "auto", borderRadius: "0" }}
                  onClick={handleView}
                  size="sm"
                  block
                >
                  Atrás
                </CustomProductButtonBack>
              </ContainerItem>
            ) : (
              <ContainerItem>
                <ProductTitle style={{ marginTop: "auto" }}>
                  {props.producto.titulo}
                </ProductTitle>
                <CustomProductButtonSeeMore
                  style={{ marginTop: "auto", borderRadius: "0" }}
                  onClick={handleView}
                  size="sm"
                  block
                >
                  Ver más
                </CustomProductButtonSeeMore>
              </ContainerItem>
            )}
          </ProductContainer>
        </div>
      </Col>
    </div>
  );
};
export default Producto;

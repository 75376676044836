import React from "react";
import FotoCamion from "../img/nosotros.jpg";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import Producto from "../components/Producto";
import CharecteristicSection from "../components/CharecteristicSection";
import DescriptionSection from "../components/DescriptionSection";
import { productosArray } from "../data/data";
import { Button } from "react-bootstrap";

const ParallaxContainer = styled.div`
  width: 100%;
  height: 520px;
  background: linear-gradient(
      90deg,
      rgba(97, 96, 101, 0.8141631652661064) 0%,
      rgba(2, 0, 36, 0.3228466386554622) 100%
    ),
    url(${FotoCamion}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
`;
const ParallaxContainerSkills = styled.div`
  width: 100%;
  height: 100%;
  padding: 32px;
  background: linear-gradient(
      90deg,
      rgba(97, 96, 101, 0.8141631652661064) 0%,
      rgba(2, 0, 36, 0.5228466386554622) 100%
    ),
    url(${FotoCamion}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: column;
`;

const ParallaxContainerQuote = styled.div`
  width: 100%;
  height: 620px;
  padding: 64px;
  background: linear-gradient(
      90deg,
      rgba(97, 96, 101, 0.8141631652661064) 0%,
      rgba(2, 0, 36, 0.5228466386554622) 100%
    ),
    url(${FotoCamion}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: column;
`;

const FirstSection = styled.section`
  padding-top: 30px;
  align-self: center;
  display: flex;
  justify-content: center;
`;
const SecondSection = styled.section`
  padding-top: 30px;
  align-self: center;
  display: flex;
  justify-content: center;
`;
const FourthSection = styled.section`
  align-self: center;
  display: flex;
  justify-content: center;
  flex-flow: column;
`;
const SixthSection = styled.section`
  padding-top: 30px;
  align-self: center;
  height: 200px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  background-color: #bb0729;
  padding: 32px;
  height: 100%;
`;
const MainTitle = styled.h1`
  color: black;
  text-align: center;
`;
const TitleSkills = styled.h1`
  color: white;
  text-align: center;
`;
const TitleAvala = styled.h4`
  color: white;
  text-align: center;
`;
const TitleQuote = styled.h1`
  color: white;
  text-align: center;
  font-size: 2.2em;
`;
const SeparatorLine = styled.hr`
  padding-bottom: 30px;
  border-top: 3px solid #bb0729;
  width: 15%;
  margin: 0 auto;
`;
const ProductRow = styled(Row)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ForteStyle = styled.h1`
  color: white;
  font-size: 5.5em;
  text-align: center;
  font-family: system-ui;
`;
const ConcretosStyle = styled.h1`
  color: white;
  font-size: 2.8em;
  text-align: center;
  font-family: system-ui;
`;
const TitleFirstStyleContainer = styled.div`
  align-self: center;
  align-items: center;
  display: flex;
  flex-flow: column;
`;

const Landing = () => {
  return (
    <div>
      <ParallaxContainer>
        <TitleFirstStyleContainer>
          <ForteStyle>FORTE</ForteStyle>
          <ConcretosStyle>CONCRETOS</ConcretosStyle>
          <Button
            variant="danger"
            style={{
              marginTop: "3em",
              borderRadius: 35,
              backgroundColor: "#bb0729",
              padding: "14px 18px",
              fontSize: "16px",
              fontWeight: "bold",
              width: "150px",
              color: "#fff",
            }}
            href="/contacto"
          >
            ¡COTIZA YA!
          </Button>
        </TitleFirstStyleContainer>
      </ParallaxContainer>
      <SixthSection>
        <DescriptionSection />
      </SixthSection>
      <FourthSection>
        <ParallaxContainerQuote>
          <div>
            <TitleQuote>
              METROS CUBICOS DE CALIDAD EN TU OBRA.
              <br /> SIEMPRE CUMPLIENDO CON LA NORMA.{" "}
            </TitleQuote>
            <SeparatorLine />
          </div>
        </ParallaxContainerQuote>
      </FourthSection>

      <FirstSection>
        <div>
          <div>
            <MainTitle>ALGUNOS DE NUESTROS PRODUCTOS </MainTitle>
            <SeparatorLine />
          </div>
          <div
            className="container-fluid"
            style={{
              padding: "16px",
              display: "flex",
              flexFlow: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ProductRow>
              {productosArray.slice(0, 6).map((item, index) => {
                return <Producto producto={item} />;
              })}
            </ProductRow>
          </div>
          <div
            style={{
              display: "flex",
              alignSelf: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="danger"
              style={{
                marginTop: "3em",
                borderRadius: 35,
                backgroundColor: "#bb0729",
                padding: "14px 18px",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#fff",
              }}
              href="/productos"
            >
              VER PRODUCTOS
            </Button>
          </div>
        </div>
      </FirstSection>

      <SecondSection>
        <ParallaxContainerSkills>
          <div>
            <TitleSkills>REALIZAMOS TODO CON </TitleSkills>
            <SeparatorLine />
          </div>
          <CharecteristicSection />
        </ParallaxContainerSkills>
      </SecondSection>
    </div>
  );
};

export default Landing;

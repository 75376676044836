import React from "react";
import ForteCamion from "../img/forte-camion.jpg";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { FaWhatsapp } from "react-icons/fa";
import { AiOutlineFacebook } from "react-icons/ai";
import { FiMail } from "react-icons/fi";

const ParallaxContainer = styled.div`
  width: 100%;
  height: 420px;
  background: url(${ForteCamion}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const MainTitle = styled.h1`
  align-self: center;
  color: black;
  background-color: white;
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const SubTitle = styled.h1`
  color: black;
`;

const SeparatorLineR = styled.hr`
  padding-bottom: 50px;
  border-top: 3px solid #bb0729;
  width: 50%;
  margin: 0 auto;
`;

const InfoCol = styled(Col)``;
const InfoRow = styled(Row)`
  height: 100px;
  margin-left: -8px;
  display: flex;
  justify-content: center;
`;

const FirstSection = styled.section`
  padding-top: 30px;
  align-self: center;
  display: flex;
  justify-content: center;
  min-height: 100% !important;
`;

const SocialMediaTitle = styled.h4`
  color: ${(props) => (props.color ? props.color : "black")};
  font-weight: bold;
`;

const SocialMediaDesc = styled.p`
  color: black;
  font-weight: bold;
`;

const ContainerContact = styled.div`
  @media (max-width: 767px) {
    height: 350px;
  }
  @media (max-width: 465px) {
    height: 350px;
  }
`;

const Contacto = () => {
  return (
    <div style={{ paddingBottom: "150px" }}>
      <ParallaxContainer style={{ textAlign: "center" }}>
        <div>
          <MainTitle>[ CONTACTO ]</MainTitle>
        </div>
      </ParallaxContainer>

      <FirstSection>
        <ContainerContact style={{ textAlign: "center", width: "100%" }}>
          <SubTitle>¡Cotiza de manera inmediata!</SubTitle>
          <SeparatorLineR />
          <InfoRow>
            <InfoCol xs={12} md={6}>
              <a
                style={{
                  color: "green",
                  paddingBottom: "50px",
                }}
                target="_blank"
                href="https://wa.me/+528187772429"
              >
                <FaWhatsapp
                  style={{ fontSize: "50px", marginBottom: "10px" }}
                />
              </a>
              <SocialMediaDesc>81 8777 2429 </SocialMediaDesc>
            </InfoCol>

            <InfoCol xs={12} md={6}>
              <a href="mailto:ventas@forteconcretos.com">
                <FiMail
                  style={{
                    fontSize: "50px",
                    color: "red",
                    marginBottom: "10px",
                  }}
                />
              </a>
              <SocialMediaDesc>ventas@forteconcretos.com</SocialMediaDesc>
            </InfoCol>
          </InfoRow>
        </ContainerContact>
      </FirstSection>
    </div>
  );
};

export default Contacto;
